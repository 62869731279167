/* @tailwind base;
@tailwind components;
@tailwind utilities; */

/*-- Google Fonts --*/


body {
  background: rgb(130,24,237);
background: linear-gradient(120deg, rgba(130,24,237,1) 19%, rgba(10,242,250,1) 100%);
  background-attachment: fixed;
  font-family: "Roboto", sans-serif;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 1;
}
#main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: #fff;
  z-index: 2;
}
.mb-3 {
  font-size: 56px;
}
#footer {
  position: absolute;  
  bottom: 12px; 
  z-index: 2;
}
.button {
  background-color: #320262;
  color: #fff;
  padding: 5px 10px 8px;
  font-size: 24px;
  border-radius: 10px;
}
.button:hover {
    text-decoration: none;
    color: #fff;
}